<template>
  <div class="carousel-add-detail">
    <el-page-header @back="goBack" content="主题--新增"> </el-page-header>
    <div class="add-content">
    </div>
    <div class="info">
      <div>
        <span> 标题:</span>
        <el-input v-model="title"></el-input>
      </div>
      <div>
        <span>主题描述:</span>
        <el-input v-model="info"></el-input>
      </div>
    </div>
    <div class="preview-img">
          <div :style="previews.div">
            <img :src="option.img" style="max-width:500px;height:auto;" />
          </div>
    </div>
    <div class="button-block">
      <el-button style="position: relative" type="primary" @click="selectImg"
        >选择图片<label for="selImg"></label
      ></el-button>
      <el-button v-show="status" type="primary" @click="submitCarousel"
        >保存</el-button
      >
      <input type="file" id="selImg" ref="img" @change="selectImg" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { INSERT } from '@/services/dao';
export default {
  data() {
    return {
      option: {
        img: "",
        outputSize: 1, //剪切后的图片质量（0.1-1）
        full: false, //输出原图比例截图 props名full
        outputType: "png",
        canMove: true,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 498,
        autoCropHeight: 303,
        fixedBox: true,
      },
      previews: {},
      info: "",
      title: "",
      status: true,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    realTime(data) {
      this.previews = data;
    },
    //文件上传
    submitCarousel() {
      // 图片文件上传
        let inputDOM = this.$refs.img;
        let file = inputDOM.files[0];
        let params = new FormData();
        params.append('file',file);
        params.append('chunk','0');
        console.log("params.get('file'): ",params.get('file'));
        let config = {
            headers: {'Content-Type': 'multipart/form-data'}
        }
        // 先判断 是否选择图片
        if(file == "" || file === undefined || file == null){
          //alert('请上传图片!');
           this.$message({
              message: '请上传图片!',
              type: 'warning'
            });
           return '';
        }else{
            // 上传了图片
            // 添加请求头
            axios.post(this.api.LoginURL.concat('/resources/trading/resourcesUpload'),params,config)
                .then(async res=>{
                    console.log("res-> ",res)
                    if(res.data.success){
                        // 调试阶段 先注释 
                        let imgURL = res.data.data.url;
                        let info = await INSERT(
                            "post",
                            "",
                            'insert_TtTheme(objects:{content:"'+
                            this.info+'",title:"'+this.title+'",img:"'+imgURL+'",createTime:"'+new Date()+'"}) {     affected_rows   }'
                        );
                        console.log(info)
                        if(!info.success){this.$message({
                            type: 'error',
                            message: '数据库错误~',
                            duration: 1200
                        })}
                        if (info.data.insert_TtTheme.affected_rows > 0) {
                            this.$message({
                                message:"新增成功!",
                                type: 'success',
                                duration: 1200
                            });
                            this.goBack();
                        }
                    }else{
                        this.$message.warning('服务器繁忙~');
                    }
                    
                })
                .catch(error=>{
                    console.log(error);
                    this.$message.warning('服务器错误~');
                })
        }
    },
    selectImg(e) {
      let inputDOM = this.$refs.img;
      if (!inputDOM.files[0]) {
        return;
      }
      if (
        inputDOM.files[0].type == "image/png" ||
        inputDOM.files[0].type == "image/jpeg" ||
        inputDOM.files[0].type == "image/bmp"
      ) {
        this.option.img = URL.createObjectURL(inputDOM.files[0]);
      } else {
        //alert("格式不正确,请上传图片文件");
        this.$message({
          message: '格式不正确,请上传图片文件!',
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style scoped>
.carousel-add-detail {
}
.add-content {
  margin-top: 10px;
}
.add-content > div {
  display: inline-block;
  vertical-align: middle;
  width: 700px;
  height: 700px;
}
.carousel-add-detail .info {
  padding: 30px 0;
  font-size: 20px;
}
.carousel-add-detail .info input {
  height: 30px;
  width: 800px;
}
.button-block label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
#selImg {
  display: none;
}
</style>